import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchIngredients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/get/ingredients', {})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  
  
    addIngredients(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/add/ingredients', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
  
    updateIngredients(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/update/ingredient', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteIngredients(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/delete/ingredients', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },



    addUser(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admins/registerAssociate', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
